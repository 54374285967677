import React from "react";

import FaqCopy from "../../atoms/FaqCopy";

import './faq.css';

function Faq(props) {
  

  return (
    <div className='container'>
      <div className='row'>
        <div className='col-full'>
          <FaqCopy />
        </div>
      </div>
    </div>
  )
}

export default Faq;
