import React, { useRef, useEffect, useState } from "react";
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import classNames from 'classnames';

import Modal from '../../molecules/Modal';
import MapDisclaimer from "../../atoms/MapDisclaimer";
import MapInstructions from "../../atoms/MapInstructions";
import DynamicMapLegend from "../../molecules/DynamicMapLegend";
import SelectedAreaInfo from "../../molecules/SelectedAreaInfo";

import './map.css';
import mapboxConfigs from '../../../map_layer_configs/mapbox.config.js';

let layerConfig;
if (process.env.REACT_APP_CITY === "BENTON") {
  layerConfig = mapboxConfigs["benton"];
}
else if (process.env.REACT_APP_CITY === "TOLEDO") {
  layerConfig = mapboxConfigs["toledo"];
}
else if (process.env.REACT_APP_CITY === "RICHMOND") {
  layerConfig = mapboxConfigs["richmond"];
}

const { legends, interactiveLayers } = layerConfig;

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

export const Map = (props) => {
  const { userEnteredLocation, handleClearLocations } = props;

  const mapContainer = useRef(null);
  const [zoom, setZoom] = useState(parseFloat(process.env.REACT_APP_MAP_ZOOM));
  const [interactionArea, setInteractionArea] = useState({
      clickedArea: null,
      interactionZoom: null,
      error: false,
  });
  const [showModal, setShowModal] = useState(true);
  const [showDisclaimer, setShowDisclaimer] = useState(true);
  // True if the drawer on mobile views is expanded
  const [isDrawerExpanded, setIsDrawerExpanded] = useState(false);

  const marker = new mapboxgl.Marker();

  /**
   * This function is called when someone either clicks the map or searches in the geocoder.
   * It takes the information from where a layer was clicked, contained in 'event',
   * and adds it to our React state.
   *
   * We don't perform any data validation here because there are a variety of valid data
   * formats we could expect to see here. Each "drawer" might have its own set of columns
   * it's expecting.
   * @param {object} event - event from clicking on the Mapbox layer
   * @param {number} zoom - the zoom level the map was at when it was clicked
   */
  const handleSelectArea = (event, zoom) => {
    const area = event.features[event.features.length-1].properties;
    setInteractionArea({
      clickedArea: area,
      interactionZoom: zoom,
    });
  }

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainer.current,
      // TODO Set to styles from BlueConduit Mapbox account
      // style: 'mapbox://styles/blueconduit-raanan/ckp45vu9n2b6d18qw2mn5yrou',
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [
        parseFloat(process.env.REACT_APP_MAP_CENTER_LON),
        parseFloat(process.env.REACT_APP_MAP_CENTER_LAT),
      ],
      zoom: zoom,
      minZoom: process.env.REACT_APP_MAP_ZOOM_MIN,
      maxZoom: process.env.REACT_APP_MAP_ZOOM_MAX,
    });

    map.addControl(new mapboxgl.NavigationControl(), 'bottom-right');

    // Wait until the map has finished loading.
    map.on('load', () => {

      // Load sources and layers
      layerConfig.sources.forEach(layer => {
        map.addSource(layer.id, {
          type: layer.type,
          url: layer.url,
        });
      });
      Object.keys(layerConfig.layers).forEach(layer => {
        map.addLayer(layerConfig.layers[layer]);
      });

      // var popup = new mapboxgl.Popup({
    	// 	closeButton: false,
    	// 	closeOnClick: false
    	// });

      interactiveLayers.forEach(layer => {
        map.on('mouseenter', layer, (event) => {
          map.getCanvas().style.cursor = 'pointer';
          // var address = event.features[0].properties['PROPERTY_A'];
          // var coordinates = event.lngLat;
          // popup.setLngLat(coordinates).setHTML('<h4>'+address+'</h4>').addTo(map);
        })

        map.on('mouseleave', layer, () => {
          map.getCanvas().style.cursor = '';
          // popup.remove();
        });

        map.on('click', layer, event => {
          //console.log(event.features[0].properties);
          if (event.lngLat) {
            marker.setLngLat(event.lngLat).addTo(map);
          }
          handleSelectArea(event, map.getZoom());
        });

        map.moveLayer(layer, "road-label");
      });

      // This prop is not null when the user searched an address on the homepage
      if (userEnteredLocation) {
        map.setCenter(userEnteredLocation.center);
        marker.setLngLat(userEnteredLocation.center).addTo(map);
        map.setZoom(16.9);
        setZoom(16.9);
        // simulating click at the search location
        // We need to wait a bit so the zoom level can get updated before we
        // fire the click event.
        setTimeout(() => {
          map.fire('click', { latLng: userEnteredLocation.center, point: map.project(userEnteredLocation.center), originalEvent: {} })
        }, 2000);
      }
    });

    // Track the zoom changes so we know which layer and legend to display
    map.on('zoom', function () {
      setZoom(map.getZoom());
    });

    const geocoder = new MapboxGeocoder({
      accessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
      countries: 'us',
      placeholder: 'Search for your address',
      marker: false,
      bbox: [
        parseFloat(process.env.REACT_APP_GEOCODER_BBOX_LON_LOW),
        parseFloat(process.env.REACT_APP_GEOCODER_BBOX_LAT_LOW),
        parseFloat(process.env.REACT_APP_GEOCODER_BBOX_LON_HIGH),
        parseFloat(process.env.REACT_APP_GEOCODER_BBOX_LAT_HIGH)
      ],
      proximity: {
        longitude: parseFloat(process.env.REACT_APP_GEOCODER_PROX_LON),
        latitude: parseFloat(process.env.REACT_APP_GEOCODER_PROX_LAT)
      },
      mapboxgl: mapboxgl,
      flyTo: false
    });
    geocoder.addTo('#geocoder');

    geocoder.on('result', event => {
      const resultCenter = event.result.center;
      // console.log(`center is ${resultCenter}`)
      marker.setLngLat(resultCenter).addTo(map);
      // map.setCenter(resultCenter);
      // map.setZoom(16.9);
      map.flyTo({center: resultCenter, zoom: 16.9})
      setZoom(16.9);

      // simulating click at the search location
      // We need to wait a bit so the zoom level can get updated before we
      // fire the click event.
      setTimeout(() => {
        map.fire('click', { latLng: resultCenter, point: map.project(resultCenter), originalEvent: {} })
      }, 2000);

    })

    // Clean up on unmount
    return () => {
      // Remove the map
      map.remove();
      // Clear the location from the homepage geocoder search
      handleClearLocations();
    }
  // TODO we should investigate if this useEffect hook is being used correctly
  // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      {showModal ?
        <Modal
          onClose={() => setShowModal(!showModal)}
        >
          {showDisclaimer ?
            <MapDisclaimer
              goToInstructions={() => setShowDisclaimer(false)}
            />
            :
            <MapInstructions/>
          }
        </Modal>
        :
        null
      }
      <div className="map-parent">

        <div className={classNames(
          "map__drawer",
          {
            "map__drawer--expanded": isDrawerExpanded,
          },
        )}>
          <button
            onClick={() => setIsDrawerExpanded(!isDrawerExpanded)}
            className="map__expand-button"
          >
            {isDrawerExpanded
            ? "Minimize"
            : "Expand" }
          </button>
          <div id='geocoder'></div>
          <DynamicMapLegend
            legends={legends}
            mapZoom={zoom}
          />
          <SelectedAreaInfo
            legends={legends}
            interactionArea={interactionArea}
          />
        </div>

        <div className="map__bounding-box">
          <div ref={mapContainer} style={{position: 'relative', height: '100%', width: '100%'}} id='map'></div>
        </div>

      </div>
    </React.Fragment>
  )
}

export default Map;
