import React from 'react';

import "./map_instructions.css";

function MapInstructions(props) {
  return (
    <React.Fragment>
      <h2>How to use this map</h2>
      <div className="map-instructions__list">
        <ol>
          <li>To find your service line info, type your address into the Search Bar in the top left-hand corner and press enter.</li>
          <li>Click on any shaded parcel area to view the water service line material at that location. The information will appear on the left-hand side of the screen. The colors on the map are explained in the legend under <b>Estimated Service Line Material.</b></li>
          <li>Click on the buttons on the bottom left-hand side to find more information and resources about this map, the data and how to get involved.</li>
        </ol>

      </div>
    </React.Fragment>
  )
}

export default MapInstructions;
