import React from 'react';
import { Switch, Route } from "react-router-dom";
import { withRouter } from "react-router";
import { withTranslation } from 'react-i18next';

import Home from './components/pages/Home';
import Map from './components/pages/Map';
import Faq from './components/pages/FAQ';
import WhatToDo from './components/pages/WhatToDo';

import Header from './components/organisms/Header';
import Footer from './components/molecules/Footer';

import './App.css';
import './styles/base.css';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userEnteredLocation: null,
    }
  }

  handleChangeLanguage = lng => {
    this.props.i18n.changeLanguage(lng);
  }

  handleEnteredLocationChange = geoCoderResult => {
    this.setState({
      userEnteredLocation: geoCoderResult,
    }, () => {
      this.props.history.push('/map');
    });
  }
  // Intended to be called when a Map component unmounts, we want to clear these
  // stored locations so they don't influence how the map renders when the user
  // visits the map next time
  handleClearLocations = () => {
    this.setState({
      userCurrentLocation: null,
      userEnteredLocation: null,
    });
  }

  render() {
    return (
      <div className="App">
        <Header
          handleChangeLanguage={this.handleChangeLanguage}
        />

        <Switch>
          <Route exact path="/">
            <div className='content'>
              <Home
                handleEnteredLocationChange={this.handleEnteredLocationChange}
              />
            </div>
          </Route>
          <Route path="/map">
            <Map
              userEnteredLocation={this.state.userEnteredLocation}
              handleClearLocations={this.handleClearLocations}
            />
          </Route>
          {process.env.REACT_APP_CITY === 'BENTON' ?
            <Route path="/what-to-do">
              <div className='content'>
                <WhatToDo/>
              </div>
            </Route>
            :
            null
          }
          <Route path="/faq">
            <div className='content'>
              <Faq />
            </div>
          </Route>
        </Switch>

        {/* Don't render the footer on the map screen */}
        <Route
          render={({ location }) => ["/map",].includes(location.pathname)
            ? null
            : <Footer />
          }
        />
      </div>
    );
  }
}

export default withRouter(withTranslation('common')(App)) ;
