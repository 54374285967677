import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import common_es from "./translations/espanol/common.json";
import common_en from "./translations/english/common.json";

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    lng: 'en',
    fallbackLng: 'en',
    debug: true,
    interpolation: { escapeValue: false }, // not needed for react as it escapes by default
    resources: {
      en: {
        common: common_en
      },
      es: {
        common: common_es
      }
    }
  });

export default i18n;