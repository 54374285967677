import React from "react";

/**
 * This component only returns a simple sentence to tell users to zoom in further
 * before they can interact with the map. It's basically a catch-all "drawer" we
 * can use when we only have layers to display at high zoom levels, but don't want
 * to restrict users to staying super zoomed in.
 */
export const NoLayerDrawer = (props) => {
  return (
    <React.Fragment>
      <p>Please zoom in further to interact with the map.</p>
    </React.Fragment>
  );
}

export default NoLayerDrawer;
