export const mapboxConfigs = {
	"toledo": {
		"sources":[
			{
				"id":"toledo-parcels-source",
				"type": "vector",
				"url": "mapbox://blueconduit.7wxgwaes"
			},
			{
				"id":"toledo-neighborhoods-source",
				"type": "vector",
				"url": "mapbox://blueconduit.8yy5wwef"
			},
		],
		 "layers":{
			"parcels": {
				"id": "parcels-score",
				"type": "fill",
				"source": "toledo-parcels-source",
				"layout": {"visibility": "visible"},
				"paint": {
					"fill-color": [
						"case",
						["all", ["==", ["get", "y_score_pub"], null], ["==", ["get", "y_score_priv"], null], true],
						`${process.env.REACT_APP_MAP_UNKNOWN}`,
						["all", ["==", ["get", "replaced_public"], 1], ["==", ["get", "replaced_private"], 1], true],
						`${process.env.REACT_APP_MAP_NON_LEAD}`,
						[
							"interpolate",
							["linear"],
							["max", ["to-number", ["get", "y_score_pub"]], ["to-number", ["get", "y_score_priv"]]],
							0,
							`${process.env.REACT_APP_MAP_NON_LEAD}`,
							0.0001,
							`${process.env.REACT_APP_MAP_LOW_LIKE}`,
							0.65,
							`${process.env.REACT_APP_MAP_MED_LIKE}`,
							0.999999,
							`${process.env.REACT_APP_MAP_HIGH_LIKE}`,
							1,
							`${process.env.REACT_APP_MAP_LEAD}`
						]
						],
						"fill-outline-color":[
							"case",
							["all", ["==", ["get", "replaced_public"], 1], ["==", ["get", "replaced_private"], 1], true],
							`${process.env.REACT_APP_MAP_MED_LIKE}`,
							"#000000"
						],
						"fill-opacity": [
							"case",
							["all", ["==", ["get", "y_score_pub"], null], ["==", ["get", "y_score_priv"], null], true],
							0.25,
							0.75
						]
				},
				"source-layer": "parcel_layer-4uiqvo"
			},
			"neighborhoods": {
				"id": "neighborhoods-score",
				"type": "fill",
				"source": "toledo-neighborhoods-source",
				"maxzoom":14,
				"layout": {"visibility": "visible"},
				"paint": {
					"fill-color": [
						"case",
						["==", ["get", "est_percent_LSLs"], null],
						`${process.env.REACT_APP_MAP_UNKNOWN}`,
						[
							"interpolate",
							["linear"],
							["get", "est_percent_LSLs"],
							0,
							`${process.env.REACT_APP_MAP_NON_LEAD}`,
							20,
							`${process.env.REACT_APP_MAP_SOME_LEAD}`,
							40,
							`${process.env.REACT_APP_MAP_LOW_LIKE}`,
							60,
							`${process.env.REACT_APP_MAP_MED_LIKE}`,
							80,
							`${process.env.REACT_APP_MAP_HIGH_LIKE}`,
							100,
							`${process.env.REACT_APP_MAP_LEAD}`
						]
					],
					"fill-opacity": [
						"case",
						["==", ["get", "est_percent_LSLs"], null],
						0.15,
						0.5
					]
				},
				"source-layer": "lsli_census_info_layer-15jcbh"
			},
		},
		"legends": [
			{
				"zoom": 14,
				"legend": "lead-likelihood",
				"drawerKey": "lead-likelihood",
			},
			{
				"zoom": 0,
				"legend": "estimated-lead-lines",
				"drawerKey": "neighborhood-summary",
			}
		],
		"interactiveLayers": [
			"parcels-score",
			"neighborhoods-score",
		],
	},
	"richmond":{
		"sources":[
			{
				"id":"richmond-parcels-source",
				"type":"vector",
				"url":"mapbox://blueconduit.6guqmmec"
			},
			{
				"id":"richmond-neighborhoods-source",
				"type": "vector",
				"url": "mapbox://blueconduit.3i2e8hp3"
			}
		],
		"layers":{
			"parcels":{
				"id": "parcels-score",
				"type": "circle",
				"source": "richmond-parcels-source",
				"layout": {"visibility": "visible"},
				"paint": {
					'circle-radius': 4,
					'circle-color': [
						"interpolate",
						["linear"],
						["max", ["to-number", ["get", "BC_StreetSideLeadScore"]], ["to-number", ["get", "BC_HomeSideLeadScore"]]],
							0,
							`${process.env.REACT_APP_MAP_NON_LEAD}`,
							0.0001,
							`${process.env.REACT_APP_MAP_LOW_LIKE}`,
							0.65,
							`${process.env.REACT_APP_MAP_MED_LIKE}`,
							0.999999,
							`${process.env.REACT_APP_MAP_HIGH_LIKE}`,
							1,
							`${process.env.REACT_APP_MAP_LEAD}`
						]
				},
				"source-layer": "richmond_predictions_111722_1-72mn0q"
			},
			"neighborhoods":{
				"id": "neighborhoods-score",
				"type": "fill",
				"source": "richmond-neighborhoods-source",
				"maxzoom":14,
				"layout": {"visibility": "visible"},
				"paint": {
					"fill-color": [
						"case",
						["<=", ["get", "Total SLs"], 2],
						`${process.env.REACT_APP_MAP_UNKNOWN}`,
						[
							"interpolate",
							["linear"],
							["get", "Percent LSL"],
							20,
							`${process.env.REACT_APP_MAP_NON_LEAD}`,
							40,
							`${process.env.REACT_APP_MAP_LOW_LIKE}`,
							60,
							`${process.env.REACT_APP_MAP_MED_LIKE}`,
							80,
							`${process.env.REACT_APP_MAP_HIGH_LIKE}`,
							100,
							`${process.env.REACT_APP_MAP_LEAD}`
						]
					],
					"fill-opacity": [
						"case",
						["==", ["get", "Percent LSL"], null],
						0.15,
						0.75
					]
				},
				"source-layer": "block_layer_11_18_22-7qutqc"
			}
		},
		"legends":[
			{
				"zoom": 14,
				"legend": "lead-likelihood",
				"drawerKey": "lead-likelihood",
			},
			{
				"zoom": 0,
				"legend": "estimated-lead-lines",
				"drawerKey": "neighborhood-summary",
			}
		],
		"interactiveLayers": [
			"parcels-score",
			"neighborhoods-score",
		],
	},
	"benton": {
		"sources":[
			{
				"id":"bh-parcels-source",
				"type": "vector",
				"url": "mapbox://blueconduit.374lhgk4"
			}
		],
		 "layers":{
			"parcels": {
				"id": "parcels-score",
				"type": "fill",
				"source": "bh-parcels-source",
				"layout": {"visibility": "visible"},
				"paint": {
					"fill-color": [
						// If either public or private are lead --> red
						// if BOTH public and private are copper --> green
						// Else show grey
						"case",
							["==", ["get", "material_recorded_public"], "LEAD"],
							`${process.env.REACT_APP_MAP_KNOWN_LEAD}`,

							["==", ["get", "material_recorded_private"], "LEAD"],
							`${process.env.REACT_APP_MAP_KNOWN_LEAD}`,

							["all",
								["==", ["get", "material_recorded_public"], "COPP"],
								["==", ["get", "material_recorded_private"], "COPP"],
							],
							`${process.env.REACT_APP_MAP_KNOWN_NON_LEAD}`,

							`${process.env.REACT_APP_MAP_UNKNOWN}`,

						],
						"fill-outline-color":[
							"case",
							["==", ["get", "replaced"], "1"],
							"#d60000",
							"#000000"
						],
						"fill-opacity": [
							"case",
							["==", ["get", "y_score"], null],
							0.25,
							0.75
						]
				},
				"source-layer": "bh_taprecords_v1-1vzwgr"
			},
		},
		"legends": [
			{
				"zoom": 14,
				"legend": "known-unknown",
				"drawerKey": "known-unknown",
			},
			{
				"zoom": 0,
				"legend": "known-unknown",
				"drawerKey": "no-layer-available",
			}
		],
		"interactiveLayers": [
			"parcels-score",
		],
	},
};

export default mapboxConfigs;
