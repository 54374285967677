import React from "react";
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';

import Hero from "../../../assets/home_image.png";
import './home.css';

class Home extends React.Component {

  componentDidMount() {
    const geocoder = new MapboxGeocoder({
      accessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
      countries: 'us',
      placeholder: 'Search for your address',
      marker: false,
      // TEMPLATE
      // new bounding box and proximity for geocoder
      bbox: [
        parseFloat(process.env.REACT_APP_GEOCODER_BBOX_LON_LOW),
        parseFloat(process.env.REACT_APP_GEOCODER_BBOX_LAT_LOW),
        parseFloat(process.env.REACT_APP_GEOCODER_BBOX_LON_HIGH),
        parseFloat(process.env.REACT_APP_GEOCODER_BBOX_LAT_HIGH)
      ],
      proximity: {
        longitude: parseFloat(process.env.REACT_APP_GEOCODER_PROX_LON),
        latitude: parseFloat(process.env.REACT_APP_GEOCODER_PROX_LAT)
      },
      // TEMPLATE
      mapboxgl: mapboxgl,
      flyTo: false
    });
    geocoder.addTo('#geocoder');
    geocoder.on('result', (event) => {
      this.props.handleEnteredLocationChange(event.result);
    });
  }

  render() {
    const { t } = this.props;
    return (
      <div className='container'>
        <div className='row'>
          <div className='col-half'>
            <h1>{t('home.h1')}</h1>
            <h4>Start with one of our mapped areas</h4>
            <a
              className='use-location-button'
              href='https://d2u44yafpgs75b.cloudfront.net/map'
              target='_blank' 
              rel='noopener noreferrer'
            >
              Toledo, OH
            </a>
            <Link
              className='use-location-button'
              to='#'
            >
              Benton Harbor, MI
            </Link>
            <h4 style={{fontWeight: 'bold'}}>{t('home.or')}</h4>
            <label htmlFor="location">{t('home.geocoder_label')}:</label>
            <div id='geocoder'></div>
            <h4>{t('home.location_disclaimer')}</h4>
          </div>
          <div className='col-half'>
            <img src={Hero} style={{maxWidth: '100%'}} alt='Home page illustration' />
            <h4>{t('home.tech_explanation')}</h4>
            <a href='https://www.blueconduit.com/' target='_blank' rel='noopener noreferrer'>{t('home.prediction_method')}</a>
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation('common')(Home);
