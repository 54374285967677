import { useState, useEffect } from "react";

// Source: https://www.netlify.com/blog/2020/12/05/building-a-custom-react-media-query-hook-for-more-responsive-apps/

/**
 * A custom React hook to test if a page satisfies a given CSS media query. Can be
 * used to help conditionally render components based on things like screen size.
 * @param {String} query A media query to test to see if the 'document' currently matches it
 * @returns True if the document currently matches, false if not
 */
export const useMediaQuery = (query) => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    /**
     * Limiting ourselves to client-side stuff here with this use of 
     * 'window'. If we need to do server-side rendering we'll need to revise
     * this or create another utility
     */
    const media = window.matchMedia(query);
    /**
     * MediaQueryList.matches: https://developer.mozilla.org/en-US/docs/Web/API/MediaQueryList/matches
     * 
     * boolean value that returns true if the document currently matches the 
     * media query list, or false if not
     */
    if (media.matches !== matches) {
      setMatches(media.matches);
    };
    const listener = () => {
      setMatches(media.matches);
    };
    media.addEventListener("change", listener);
    return () => media.removeEventListener("change", listener);
  }, [matches, query]);

  return matches;
}