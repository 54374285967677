import React from "react";
import { useTranslation } from 'react-i18next';
import PropTypes from "prop-types";

import LeadLikelihoodDrawer from "../../atoms/LeadLikelihoodDrawer";
import NeighborhoodSummaryDrawer from "../../atoms/NeighborhoodSummaryDrawer";
import KnownUnknownDrawer from "../../atoms/KnownUnknownDrawer";
import NoLayerDrawer from "../../atoms/NoLayerDrawer";

import { ReactComponent as PointerIcon } from '../../../assets/pointer_icon.svg';
import { ReactComponent as MouseIcon } from '../../../assets/mouse_icon.svg';
import { ReactComponent as InOutIcon } from '../../../assets/in_out_icon.svg';

import "./selected_area_info.css";

function SelectedAreaInfo(props) {
  const { interactionArea, legends } = props;
  const { clickedArea, interactionZoom } = interactionArea;
  const { t } = useTranslation('common');

  if (clickedArea) {
    // find the drawer-type we should render
    let drawerKey = '';
    for (const legend of legends) {
      if (interactionZoom > legend.zoom) {
        drawerKey = legend.drawerKey;
        break;
      }
    }

    switch (drawerKey) {
      case 'lead-likelihood':
        return (
          <LeadLikelihoodDrawer 
            clickedArea={clickedArea}
            publicPrivate={process.env.REACT_APP_SHOW_PUBLIC_PRIVATE === 'true' ? true : false}
          />
        )
      case 'neighborhood-summary':
        return (
          <NeighborhoodSummaryDrawer
            clickedArea={clickedArea}
          />
        )
      case 'known-unknown':
        return (
          <KnownUnknownDrawer
            clickedArea={clickedArea}
            publicPrivate={process.env.REACT_APP_SHOW_PUBLIC_PRIVATE === 'true' ? true : false}
          />
        )
      case 'no-layer-available':
        return (
          <NoLayerDrawer />
        )
      default:
        return (
          <div>There was an error, please try again later</div>
        )
    }
  }
  // Initial render state while we wait on someone to click on the map
  else {
    return (
      <React.Fragment>
        <div style={{marginTop: '2rem'}}>
          <div style={{display: 'flex', margin: '1rem 0'}}>
            <div style={{flex: '25%'}}>
              <PointerIcon height={48} width={48}/>
            </div>
            <h4 style={{flex: '75%', margin: '0px 5px'}}>{t("map.instructions.click_1")}</h4>
          </div>
          <div style={{display: 'flex', margin: '1rem 0'}}>
            <div style={{flex: '25%'}}>
              <MouseIcon height={48} width={48}/>
            </div>
            <h4 style={{flex: '75%', margin: '0px 5px'}}>{t("map.instructions.click_2")}</h4>
          </div>
          <div style={{display: 'flex', margin: '1rem 0'}}>
            <div style={{flex: '25%'}}>
              <InOutIcon height={48} width={48}/>
            </div>
            <h4 style={{flex: '75%', margin: '0px 5px'}}>{t("map.instructions.click_3")}</h4>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

SelectedAreaInfo.propTypes = {
  /**
   * An object containing information about the area a user just interacted
   * with. It will be from the click/touch event from the map. There is not a
   * guarantee that the data in this prop is "valid", that is up to the "drawer"
   * component that will be rendering this data.
   */
  interactionArea: PropTypes.object,

  /**
   * An array of objects that describes which legend should be shown at which
   * zoom level of the map. Should follow this format:
   * {
   *   "zoom": number,
   *   "legend": legend-key,
   * }
   * At zoom levels higher than `zoom`, the `legend-key` will be rendered. There
   * should be a default/catch-all value with a zoom level of 0 to ensure that
   * a legend will always be rendered
   */
  legends: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default SelectedAreaInfo;
