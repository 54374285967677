import React, { useEffect, useRef, useCallback } from 'react';
import classNames from 'classnames'

import { ReactComponent as CloseIcon} from '../../../assets/x_icon.svg';

import './modal.css';

function Modal({ onClose, children, ...props}) {
  
  // handleClose without the useCallback gives us a compilation warning
  const handleClose = useCallback(
    () => {
      onClose();
    },
    [onClose],
  ) 
  const modalRef = useRef(null);

  useEffect(() => {
    // Closes the modal if there's a click outside the modal
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        handleClose();
      }
    }

    // Bind a mousedown listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the mousedown listener on clean up / unmount
      document.removeEventListener("mousedown", handleClickOutside);
    }
  }, [modalRef, handleClose]);

  const classes = classNames(
    "pm-modal",
  )


  return (
    <div className={classes}>
      <div className="pm-modal__content" ref={modalRef} role="dialog">
        <div onClick={handleClose} className="pm-modal__close">
          <CloseIcon />
        </div>
        {children}
      </div>
    </div>
  )
}

export default Modal;