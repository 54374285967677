import React, { useState } from 'react';

import Modal from '../../molecules/Modal';
import DesktopHeader from '../../molecules/DesktopHeader';
import MobileHeader from '../../molecules/MobileHeader';
import ContactUsForm from '../../atoms/ContactUsForm';

import { useMediaQuery } from '../../../utils/useMediaQuery';

import './header.css';

export const Header = ({ props }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  }
  const hideModal = () => {
    setIsModalOpen(false);
  }

  return (
    <header className='header'>
      {isModalOpen ?
        <Modal
          onClose={hideModal}
        >
          <ContactUsForm
            onSuccess={hideModal}
          />
        </Modal>
        :
        null
      }

      {useMediaQuery("(max-width: 875px") ?
        <MobileHeader showModal={showModal} />
        :
        <DesktopHeader showModal={showModal} />
      }

    </header>
  );
};

export default Header;
