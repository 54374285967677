import React from "react";
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";

import HeaderLogo from "../../atoms/HeaderLogo";

export const DesktopHeader = ({ showModal, ...props }) => {
  const { t, i18n } =useTranslation("common");
  let location = useLocation();

  const handleChangeLanguage = (event) => {
    i18n.changeLanguage(event.target.value);
  }

  return (
    <nav className="topnav mobile-hidden">
      <HeaderLogo />
      <ul
        className="topnav__links"
        role="navigation"
      >
        <li>
          <Link
            className={classNames(
              "topnav__links__tab",
              {
                'topnav__links__tab--active': location.pathname === '/map'
              }
            )}
            to="/map"
            >
              {t('header.nav_links.map')}
          </Link>
        </li>
        <li>
          <Link
            className={classNames(
              "topnav__links__tab",
              {
                'topnav__links__tab--active': location.pathname === '/faq'
              }
            )}
            to="/faq"
          >
            {t('header.nav_links.faq')}
          </Link>
        </li>
        {process.env.REACT_APP_CITY === 'BENTON' ?
          <li>
            <Link
              className={classNames(
                "topnav__links__tab",
                {
                  'topnav__links__tab--active': location.pathname === '/what-to-do'
                }
              )}
              to="/what-to-do"
            >
              {t('header.nav_links.wtd')}
            </Link>
          </li>
        :
          null
        }
      </ul>
      <label 
        htmlFor="language-select"
        className="visually-hidden"
      >
        Select language
      </label>
      <select 
        value={i18n.language} 
        onChange={handleChangeLanguage}
        className="topnav__language-select"
        id="language-select"
      >
        <option value="en">English (U.S.)</option>
        <option value="es">Espa&#241;ol</option>
      </select>

      <button className="topnav__button" onClick={showModal}>{t('header.contact_us')}</button>
    </nav>
  )
}

export default DesktopHeader;
