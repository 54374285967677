import React from 'react';
import { useTranslation } from 'react-i18next';

export const EstimatedLeadLinesLegend = () => {
  const { t } = useTranslation('common');

  return (
    <div id="grid-score" className="legend">
      <h4 style={{display: 'inline-block'}}>{t('map.legend.legend_title.total')}&nbsp;<i className="fa fa-info-circle" display="inline-block" data-toggle="tooltip" data-placement="left" title="BlueConduit uses a mathematical model to predict whether water service lines might be made of lead or galvanized steel when the material has not yet been verified. For more information, click 'FAQ' in the menu above."></i></h4>
      <div><span style={lead}></span>80-100%</div>
      <div><span style={high_like}></span>60-80%</div>
      <div><span style={med_like}></span>40-60%</div>
      <div><span style={low_like}></span>20-40%</div>
      <div><span style={non_lead}></span>0-20%</div>
    </div>
  );
}

const lead = {backgroundColor: `${process.env.REACT_APP_MAP_LEAD}`, border: "solid 1px #000000"}
const high_like = {backgroundColor: `${process.env.REACT_APP_MAP_HIGH_LIKE}`, border: "solid 1px #000000"}
const med_like = {backgroundColor: `${process.env.REACT_APP_MAP_MED_LIKE}`, border: "solid 1px #000000"}
const low_like = {backgroundColor: `${process.env.REACT_APP_MAP_LOW_LIKE}`, border: "solid 1px #000000"}
const non_lead = {backgroundColor: `${process.env.REACT_APP_MAP_NON_LEAD}`, border: "solid 1px #000000"}

export default EstimatedLeadLinesLegend;
