import React from 'react';
import PropTypes from "prop-types";

import EstimatedLeadLinesLegend from '../../atoms/EstimatedLeadLinesLegend';
import LeadLikelihoodLegend from '../../atoms/LeadLikelihoodLegend';
import KnownUnknownLegend from '../../atoms/KnownUnknownLegend';

/**
 * This component takes in the current zoom level of the map and a city's
 * legend configuration. With the configuration we can know which legend to
 * render at any given zoom level for that city. We iterate through the config array to
 * get the legend's key that we should render, and the switch statement
 * helps us render the correct legend.
 */
function DynamicMapLegend(props) {
  const { mapZoom, legends } = props;

  // legendKey is how we pull out the feature flag for which legend to use
  // at the current zoom level. It's based on the city's config in the 
  // mapbox.config.js file
  let legendKey = '';
  for (const legend of legends) {
    if (mapZoom > legend.zoom) {
      // Because the list is sorted, as soon as we find a legend that we're
      // zoomed in enough for we know that's the legend we want and can break
      legendKey = legend.legend;
      break;
    }
  }
  
  switch (legendKey) {
    case 'estimated-lead-lines':
      return (
        <EstimatedLeadLinesLegend />
      )
    case 'lead-likelihood':
      return (
        <LeadLikelihoodLegend />
      );
    case 'known-unknown':
      return (
        <KnownUnknownLegend />
      )
    default:
      return (
        <div>There was an error, please try again later</div>
      )
  }
}

DynamicMapLegend.propTypes = {
  /**
   * The current zoom level of the Mapbox map
   */
  mapZoom: PropTypes.number.isRequired,

  /**
     * An array of objects that describes which legend should be shown at which
     * zoom level of the map. Should follow this format:
     * {
     *   "zoom": number,
     *   "legend": legend-key,
     * }
     * At zoom levels higher than `zoom`, the `legend-key` will be rendered. There
     * should be a default/catch-all value with a zoom level of 0 to ensure that
     * a legend will always be rendered
     */
   legends: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default DynamicMapLegend;
