import React from "react";

import TopicGroup from '../../Wtd_TopicGroup';

import './what_to_do.css';

// const modalConfig = {
//   title: 'Request more data about your pipe',
//   formID: 'data-request',
//   handleSubmit: this.submitModal,
//   fields: [
//     {
//       label: 'Enter your name',
//       id: 'name',
//       type: 'text',
//     },
//     {
//       label: 'Enter your email',
//       id: 'email',
//       type: 'email',
//     },
//     {
//       label: 'Enter your address',
//       id: 'address',
//       type: 'text',
//     },
//     {
//       label: 'Enter any other comments',
//       id: 'comments',
//       type: 'text',
//     }
//   ]
// }

function WhatToDo(props) {

  // showModal = () => {
  //   this.setState({
  //     showModal: true,
  //   });
  // }
  // hideModal = () => {
  //   this.setState({
  //     showModal: false,
  //   });
  // }
  // submitModal = data => {
  //   axios.post('/data_request', data)
  //   .then(res => {
  //     this.hideModal();
  //   })
  //   .catch(error => {
  //     console.log(error);
  //   });
  // }
    
  return (
    <div className='container' >
      <div className='row'>
        <div className='col-full'>
          <TopicGroup />
        </div>
      </div>
    </div>
  )
}

export default WhatToDo;
