import React from 'react';
import { useTranslation } from 'react-i18next';

export const KnownUnknownLegend = () => {
  const { t } = useTranslation('common');

  return (
    <div id="parcels-score" className="legend">
      <h4 style={{display: 'inline-block'}}>Water Service Line Material &nbsp;<i class="fa fa-info-circle" display="inline-block" data-toggle="tooltip" data-placement="left" title=""></i></h4>
      <div><span style={known_lead}></span>Known Lead</div>
      <div><span style={known_non_lead}></span>Known Non-Lead</div>
      <div><span style={unknown}></span>{t('map.legend.city_level.unknown')}</div>
    </div>
  )
}

const known_lead = {backgroundColor: `${process.env.REACT_APP_MAP_KNOWN_LEAD}`}
const known_non_lead = {backgroundColor: `${process.env.REACT_APP_MAP_KNOWN_NON_LEAD}`}
const unknown = {backgroundColor: `${process.env.REACT_APP_MAP_UNKNOWN}`}

export default KnownUnknownLegend;
