import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import LeadIndicator from "../LeadIndicator";

const validateData = data => {
  return true;
}

export const KnownUnknownDrawer = ({ clickedArea, publicPrivate, ...props }) => {

  const { t } = useTranslation('common');

  if ( !validateData(clickedArea) ) {
    return (
      <React.Fragment>
        <p>We're sorry, there was an error with our data at this location, please try again later.</p>
        <p>If this issue persists, you can contact us at <a href="mailto:info@blueconduit.com">info@blueconduit.com</a></p>
      </React.Fragment>
    );
  }

  const address = clickedArea["PROPERTY_A"];
  let publicSideMessage = '';
  let privateSideMessage = '';
  let publicVariant = '';
  let privateVariant = '';

  if (clickedArea["material_recorded_public"]) {
    if (clickedArea["material_recorded_public"] === 'LEAD') {
      publicSideMessage = 'There is lead in your service line.';
      publicVariant = 'known';
    }
    else {
      publicSideMessage = 'There is no lead in your service line.';
      publicVariant = 'none';
    }
  }
  else {
    publicSideMessage = 'The service line material is unknown';
    publicVariant = 'unknown';
  }

  if (clickedArea["material_recorded_private"]) {
    if (clickedArea["material_recorded_private"] === 'LEAD') {
      privateSideMessage = 'There is lead in your service line.';
      privateVariant = 'known';
    }
    else {
      privateSideMessage = 'There is no lead in your service line.';
      privateVariant = 'none';
    }
  }
  else {
    privateSideMessage = 'The service line material is unknown';
    privateVariant = 'unknown';
  }

  return (
    <React.Fragment>
      <h3>{ address }</h3>
      <LeadIndicator
        header="City-owned Portion"
        headerTooltip={t('faq.cat_1.a_1')}
        message={publicSideMessage}
        variant={publicVariant}
      />
      <LeadIndicator
        header="Customer-owned Portion"
        headerTooltip={t('faq.cat_1.a_1')}
        message={privateSideMessage}
        variant={privateVariant}
      />
      <p>Service line materials on this map reflect data from historical records. For more information, visit our <Link to="/faq">FAQ page</Link> or contact your utility.</p>
    </React.Fragment>
  )
}

export default KnownUnknownDrawer;
