import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as BcLogo } from "../../../assets/BC_logo_horizontal.svg";

import "./map_disclaimer.css";

function MapDisclaimer(props) {
  const { t, i18n } =useTranslation("common");
  const { goToInstructions } = props;

  const handleChangeLanguage = (event) => {
    i18n.changeLanguage(event.target.value);
  }

  const paragraphs = t(`map.disclaimer.copy_${process.env.REACT_APP_CITY}`).split('\n');

  return (
    <React.Fragment>
    <div className="disclaimer-container">
      <h4>{t(`map.disclaimer.title_${process.env.REACT_APP_CITY}`)}</h4>

      <label
        htmlFor="language-select"
        className="visually-hidden"
      >
        Select language
      </label>
      <select
        value={i18n.language}
        onChange={handleChangeLanguage}
        className="topnav__language-select"
        id="language-select"
      >
        <option value="en">English (U.S.)</option>
        <option value="es">Espa&#241;ol</option>
      </select>
    </div>

    { paragraphs.map((paragraph, index) => <p key={index}>{paragraph}</p>)}

    <div className="disclaimer__footer">
      <button className="disclaimer__button" onClick={goToInstructions}>How to use this map</button>
      <span className="disclaimer__footer__logo">{t('map.disclaimer.tag_line')} <BcLogo height={32}/></span>
    </div>
    </React.Fragment>
  )
}

export default MapDisclaimer;
