import React from "react";
import { useTranslation } from 'react-i18next';

import LeadIndicator from "../LeadIndicator";

const validateData = data => {
  // Not valid if the property address isn't a string
  if (typeof data.PROPERTY_A != 'string') {
    return false;
  }
  // This is a public parcel we don't have data for
  if (!data.y_score_pub) {
    return true;
  }
  // This is a private parcel we don't have data for
  if (!data.y_score_priv) {
    return true;
  }
  // Not valid if the public y_score value isn't between 0 and 1
  if (data.y_score_pub < 0 || data.y_score_pub > 1) {
    return false;
  }
  // Not valid if the private y_score value isn't between 0 and 1
  if (data.y_score_priv < 0 || data.y_score_priv > 1) {
    return false;
  }
  return true;
}

export const ToledoLeadLikelihoodDrawer = ({ clickedArea, publicPrivate, ...props }) => {
  const { t } = useTranslation('common');
  // Data at this area is invalid
  if ( !validateData(clickedArea) ) {
    return (
      <React.Fragment>
        <p>We're sorry, there was an error with our data at this location, please try again later.</p>
        <p>If this issue persists, you can contact us at <a href="mailto:info@blueconduit.com">info@blueconduit.com</a></p>
      </React.Fragment>
    );
  }

  let address = clickedArea["PROPERTY_A"];
  let score_pub = clickedArea["y_score_pub"];
  let score_priv = clickedArea["y_score_priv"];
  let replaced_public = clickedArea["replaced_public"];
  let replaced_private = clickedArea["replaced_private"];
  let curr_private = clickedArea["curr_private"];
  let datereplaced = clickedArea["lslr_date"];
  let estReplacementDate = clickedArea["PROJECTED_SEASON"]

  let publicSideMessage = '';
  let privateSideMessage = '';
  let publicVariant = '';
  let privateVariant = '';

  // This value seems to be false if someone clicks on a parcel where there is
  // no data. It's not invalid, there's just no prediction there.
  if (! (clickedArea.y_score_priv || clickedArea.y_score_pub || clickedArea.replaced_private || clickedArea.replaced_public || clickedArea.curr_private || clickedArea.curr_public)) {
    return (
      <React.Fragment>
       <h3 className="parcel__address">{address}</h3>
       <p>{t('map.data_drawer.parcel.lead_likelihood.no_data')}</p>
      </React.Fragment>
    )
  }

  // Sanity check to make sure the public risk score is a valid value
  if (score_pub <= 1) {
    if (score_pub === 0) {
      if ((replaced_public === 1 || replaced_public === "1") && datereplaced !== undefined) {
        publicSideMessage = t('map.data_drawer.parcel.lead_likelihood.replaced') + datereplaced;
      } else {
        publicSideMessage = t('map.data_drawer.parcel.lead_likelihood.no_lead');
      }
      publicVariant = 'none';
    } else if (score_pub <= 0.1) {
      publicSideMessage = t('map.data_drawer.parcel.lead_likelihood.low_like');
      publicVariant = 'low';
    } else if (score_pub <= 0.65) {
      publicSideMessage = t('map.data_drawer.parcel.lead_likelihood.med_like');
      publicVariant = 'medium';
    } else if (score_pub < 1) {
      publicSideMessage = t('map.data_drawer.parcel.lead_likelihood.high_like') + estReplacementDate;
      publicVariant = 'high';
    } else if (score_pub === 1) {
      publicSideMessage = t('map.data_drawer.parcel.lead_likelihood.lead') + estReplacementDate;
      publicVariant = 'known';
    }
  } else {
    publicSideMessage = t('map.data_drawer.parcel.lead_likelihood.unknown');
    publicVariant = 'unknown';
  }

  // Sanity check to make sure the private risk score is a valid value
  if (score_priv <= 1) {
    if (score_priv === 0) {
      if ((replaced_private === 1 || replaced_private === "1")) {
        if (datereplaced !== undefined) {
          privateSideMessage = t('map.data_drawer.parcel.lead_likelihood.replaced') + datereplaced;
        } else {
          privateSideMessage = t('map.data_drawer.parcel.lead_likelihood.no_lead');
        }
      } else {
        if (curr_private === "COPP") {
          privateSideMessage = t('map.data_drawer.parcel.lead_likelihood.no_lead');
        } else {
          privateSideMessage = t('map.data_drawer.parcel.lead_likelihood.no_lead') + " This is assumed because of the year built or the year of installation.";
        }
      }
      privateVariant = 'none';
    } else if (score_priv <= 0.1) {
      privateSideMessage = t('map.data_drawer.parcel.lead_likelihood.low_like');
      privateVariant = 'low'
    } else if (score_priv <= 0.65) {
      privateSideMessage = t('map.data_drawer.parcel.lead_likelihood.med_like');
      privateVariant = 'medium';
    } else if (score_priv < 1) {
      privateSideMessage = t('map.data_drawer.parcel.lead_likelihood.high_like') + estReplacementDate;
      privateVariant = 'high'
    } else if (score_priv === 1) {
      privateSideMessage = t('map.data_drawer.parcel.lead_likelihood.lead') + estReplacementDate;
      privateVariant = 'known';
    }
  } else {
    privateSideMessage = t('map.data_drawer.parcel.lead_likelihood.unknown');
    privateVariant = 'unknown'
  }

  return (
    <React.Fragment>
      <h3 className="parcel__address">{address}</h3>
      <LeadIndicator
        header="City-owned Portion"
        headerTooltip={t('faq.cat_1.a_1')}
        message={publicSideMessage}
        variant={publicVariant}
      />
      <LeadIndicator
        header="Customer-owned Portion"
        headerTooltip={t('faq.cat_1.a_1')}
        message={privateSideMessage}
        variant={privateVariant}
      />
      <p>{t('map.estimates')}</p>
    </React.Fragment>

  );
}

export default ToledoLeadLikelihoodDrawer;
