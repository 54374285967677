import React from "react";

const validateData = data => {
  // Not valid if average year built isn't a number
  if (typeof data["avg_year_built"] != 'number') {
    return false;
  }
  // Not valid if Median household income isn't a number
  if (typeof data["estimate_median_household_income_in_the_past_12_months_(in_2020_inflation-adjusted_dollars)"] != 'number') {
    return false;
  }
  // Not valid if % Black/AA isn't a number
  if (typeof data["estimate_percent_black_or_african_american_alone"] != 'number') {
    return false;
  }
  // Not valid if Vacancy rate isn't a number
  if (typeof data["estimate_percent_vacant"] != 'number') {
    return false;
  }
  return true;
}

export const ToledoNeighborhoodSummaryDrawer = ({ clickedArea, ...props }) => {

  // Data at this area is invalid
  if ( !validateData(clickedArea) ) {
    return (
      <React.Fragment>
        <p>We're sorry, there was an error with our data at this location, please try again later.</p>
        <p>If this issue persists, you can contact us at <a href="mailto:info@blueconduit.com">info@blueconduit.com</a></p>
      </React.Fragment>
    );
  }

  const GEOID = clickedArea['GEOID'];
  const neighborhood = clickedArea["neighborhood(s)"];
  const est_lsl_pub = Number(clickedArea['est_num_LSLs_pub'].toFixed(0)).toLocaleString();
  const est_lsl_priv = Number(clickedArea['est_num_LSLs_priv'].toFixed(0)).toLocaleString();
  const year_built_mean = Math.round(clickedArea["avg_year_built"]);
  const median_household_income = Number(clickedArea["estimate_median_household_income_in_the_past_12_months_(in_2020_inflation-adjusted_dollars)"].toFixed(0)).toLocaleString();
  const estimate_black_or_aa = clickedArea["estimate_percent_black_or_african_american_alone"].toFixed(0);
  const estimate_percent_vacant = clickedArea["estimate_percent_vacant"].toFixed(0);
  return (
    <React.Fragment>
      <h3>{neighborhood} - Census Block {GEOID.substring(GEOID.length-3)}</h3>
      <div className="neighborhood__data-point">
        <h5 className="neighborhood__data-point__text">Estimated Total Lead Service Lines (City-Owned)</h5>
        <p className="neighborhood__data-point__text">{est_lsl_pub}</p>
      </div>
      <div className="neighborhood__data-point">
        <h5 className="neighborhood__data-point__text">Estimated Total Lead Service Lines (Customer-Owned)</h5>
        <p className="neighborhood__data-point__text">{est_lsl_priv}</p>
      </div>
      <div className="neighborhood__data-point">
        <h5 className="neighborhood__data-point__text">Average Year Built</h5>
        <p className="neighborhood__data-point__text">{year_built_mean}</p>
      </div>
      <div className="neighborhood__data-point">
        <h5 className="neighborhood__data-point__text">Median Household Income (2019)</h5>
        <p className="neighborhood__data-point__text">${median_household_income.toLocaleString()}</p>
      </div>
      <div className="neighborhood__data-point">
        <h5 className="neighborhood__data-point__text">Est. Black or African American Population (%)</h5>
        <p className="neighborhood__data-point__text">{estimate_black_or_aa}%</p>
      </div>
      <div className="neighborhood__data-point">
        <h5 className="neighborhood__data-point__text">Est. Housing Vacancy Rate</h5>
        <p className="neighborhood__data-point__text">{estimate_percent_vacant}%</p>
      </div>
      <p>Data Sources:</p>
      <p>American Community Survey (5-year Estimates)</p>
      <p>Lucas County Assessor AREIS Database</p>
    </React.Fragment>
  )
}

export default ToledoNeighborhoodSummaryDrawer;
