import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import classNames from "classnames";

import HeaderLogo from "../../atoms/HeaderLogo";
import { ReactComponent as MenuIcon } from '../../../assets/menu.svg';
import { ReactComponent as CaretIcon } from "../../../assets/caret-down-fill.svg";
import { ReactComponent as MailIcon } from "../../../assets/envelope-fill.svg";
import { ReactComponent as CloseIcon } from "../../../assets/x.svg";

export const MobileHeader = ({ showModal, ...props }) => {
  const { t, i18n } = useTranslation("common");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [langListOpen, setLangListOpen] = useState(false);

  const closeMobileDrawer = () => {
    setLangListOpen(false);
    setDrawerOpen(false);
  }

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    closeMobileDrawer();
  }

  const handleContactUs = () => {
    closeMobileDrawer();
    showModal();
  }

  return (
    <nav className="mobile-navbar desktop-hidden">
      <span 
        className={classNames(
          "mobile-navbar__backdrop",
          {
            "mobile-navbar__drawer--closed": !drawerOpen
          }
        )}
        onClick={closeMobileDrawer}
      ></span>
      <div className="mobile-navbar__top">
        <HeaderLogo />
        <button 
          className="mobile-navbar__toggle"
          aria-label="Toggle navigation drawer"
          aria-expanded={drawerOpen}
          onClick={() => setDrawerOpen(!drawerOpen)}
        >
          {drawerOpen ?
            <CloseIcon height={32} width={32} />
            :
            <MenuIcon height={32} width={32}/>
          }
        </button>
      </div>
      <div className={classNames(
        "mobile-navbar__drawer",
        {
          "mobile-navbar__drawer--closed": !drawerOpen
        }
      )}
      >
        <ul className="mobile-navbar__drawer__items">
          <li>
            <Link
              className={classNames(
                "topnav__links__tab",
              )}
              to="/map"
              onClick={closeMobileDrawer}
              >
                {t('header.nav_links.map')}
            </Link>
          </li>
          <li>
            <Link
              className={classNames(
                "topnav__links__tab",
              )}
              to="/faq"
              onClick={closeMobileDrawer}
            >
              {t('header.nav_links.faq')}
            </Link>
          </li>
          {process.env.REACT_APP_CITY === 'BENTON' ?
            <li>
              <Link
                className={classNames(
                  "topnav__links__tab",
                )}
                to="/what-to-do"
              >
                {t('header.nav_links.wtd')}
              </Link>
            </li>
          :
            null
          }
          <hr style={{color: "#fbfbfb"}}/>
          <li>
            <button 
              className="mobile-navbar__btn"
              onClick={() => setLangListOpen(!langListOpen)}
            >
              Language
              <CaretIcon 
                height={16} 
                width={16}
                fill="#5ca3ea"
                className={classNames(
                  "mobile-navbar__icon",
                  {
                    "mobile-navbar__icon--rotate": langListOpen,
                  }
                )}
              />
            </button>
            <ul className={classNames(
              "mobile-navbar__lang-list",
              "mobile-navbar__drawer__nested-items",
              {
                "mobile-navbar__lang-list--open": langListOpen,
              }
            )}>
              <li onClick={() => changeLanguage("en")}>
                English (U.S.)
              </li>
              <li onClick={() => changeLanguage("es")}>
                Espa&#241;ol
              </li>
            </ul>
          </li>
          <li>
            <button 
              className="mobile-navbar__btn"
              onClick={handleContactUs}  
            >
              {t('header.contact_us')}
              <MailIcon height={16} width={16} fill="#5ca3ea" />
            </button>
          </li>
        </ul>
      </div>
      
    </nav>
  )
}

export default MobileHeader;
