import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as BcLogo } from "../../../assets/BC_logo_horizontal.svg";

import './footer.css';

function Footer(props) {
  const { t } = useTranslation('common');
  return (
    <footer>
      <p>{t('footer.tagline')}</p>
      <a href='https://www.blueconduit.com/' target="_blank" rel="noopener noreferrer">
        <BcLogo height={72} />
      </a>
    </footer>
  );
}

export default Footer;
