import React from "react";
import { useTranslation } from 'react-i18next';

function FaqCopy(props) {
  const { t } = useTranslation('common');

  return (
    <React.Fragment>
      <h1>{t('faq.h1')}</h1>
      <h2>{t('faq.on_this_page')}</h2>
      <ul>
        <li><a href='#service-lines'>{t('faq.cat_1.cat_name')}</a></li>
          <ul>
            <li><a href='#public-private'>{t('faq.cat_1.q_1')}</a></li>
            <li><a href='#lead-sl'>{t('faq.cat_1.q_2')}</a></li>
          </ul>
        <li><a href='#predictions'>{t('faq.cat_2.cat_name')}</a></li>
          <ul>
            <li><a href='#likely-lead'>{t('faq.cat_2.q_1')}</a></li>
            <li><a href='#math-model'>{t('faq.cat_2.q_2')}</a></li>
          </ul>
        <li><a href='#map-guide'>{t('faq.cat_3.cat_name')}</a></li>
          <ul>
            <li><a href='#missing-data'>{t('faq.cat_3.q_1')}</a></li>
            <li><a href='#map-colors'>{t('faq.cat_3.q_2')}</a></li>
          </ul>
      </ul>

      <h2 id='service-lines'>{t('faq.cat_1.cat_name')}</h2>
      <p>{t('faq.cat_1.cat_description')}</p>
      <h3 id='public-private'>{t('faq.cat_1.q_1')}</h3>
      <p>{t('faq.cat_1.a_1')}</p>
      <h3 id='lead-sl'>{t('faq.cat_1.q_2')}</h3>
      <p>{t('faq.cat_1.a_2')}</p>

      <h2 id='predictions'>{t('faq.cat_2.cat_name')}</h2>
      <p>{t('faq.cat_2.cat_description')}</p>
      <h3 id='likely-lead'>{t('faq.cat_2.q_1')}</h3>
      <p>
        {t('faq.cat_2.a_1')} <a href='https://arxiv.org/abs/1806.10692' target="_blank" rel="noopener noreferrer">{t('faq.cat_2.a_1_link')}</a>
      </p>
      <h3 id='math-model'>{t('faq.cat_2.q_2')}</h3>
      <p>{t('faq.cat_2.a_2')}</p>

      <h2 id='map-guide'>{t('faq.cat_3.cat_name')}</h2>
      <p>{t('faq.cat_3.cat_description')}</p>
      <h3 id='missing-data'>{t('faq.cat_3.q_1')}</h3>
      <p>{t('faq.cat_3.a_1_1')}</p>
      <p>{t('faq.cat_3.a_1_2')}</p>

      <h3 id='map-colors'>{t('faq.cat_3.q_2')}</h3>
      <p>{t('faq.cat_3.a_2_1')}</p>
      <p>{t('faq.cat_3.a_2_2')}</p>
      <p>{t('faq.cat_3.a_2_3')}</p>
      <p>{t('faq.cat_3.a_2_4')}</p>
    </React.Fragment>
  )
}

export default FaqCopy;