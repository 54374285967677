import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import "./lead_indicator.css";

const city_name = process.env.REACT_APP_CITY;



export const LeadIndicator = ({ header, headerTooltip, message, variant, ...props}) => {

  const classDict = {
    [`${city_name}-lead-indicator--known`]: variant === 'known',
    [`${city_name}-lead-indicator--high`]: variant === 'high',
    [`${city_name}-lead-indicator--medium`]: variant === 'medium',
    [`${city_name}-lead-indicator--low`]: variant === 'low',
    [`${city_name}-lead-indicator--none`]: variant === 'none',
    [`${city_name}-lead-indicator--unknown`]: variant === 'unknown',
  }

  const indicatorClasses = classNames(
    city_name+'-lead-indicator',
    classDict
  )
  return (
    <div className={indicatorClasses}>
      {header ?
        <h4>{header}&nbsp;<i className="fa fa-info-circle" display="inline-block" data-toggle="tooltip" data-placement="left" title={headerTooltip}></i></h4>
        :
        null
      }
      <p>{message}</p>
    </div>
  )
}

LeadIndicator.propTypes = {
  header: PropTypes.string,
  headerTooltip: PropTypes.string,
  message: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['known', 'high', 'medium', 'low', 'none', 'unknown']),
}

export default LeadIndicator;
