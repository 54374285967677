import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios';

import './contact_us_form.css';

export const ContactUsForm = ({ onSuccess, onError, ...props }) => {
  const { t } = useTranslation('common');
  const recaptchaRef = useRef();
  const nameRef = useRef();
  const emailRef = useRef();
  const messageRef = useRef();
  const [error, setError] = useState('');
  const [validation, setValidation] = useState({'name':true, 'email':true, 'message':true});
  
  const onSubmit = async (event) => {
    event.preventDefault();
    const token = await recaptchaRef.current.executeAsync();
    recaptchaRef.current.reset();
    const data = {
      "recaptcha": token,
      "name": nameRef.current.value,
      "email": emailRef.current.value,
      "message": messageRef.current.value,
    };

    validateField(data);

    if (formIsValid(validation)) {
      axios.post(`${process.env.REACT_APP_API_URL}/email/send_email`, data)
      .then(res => {
        onSuccess();
      })
      .catch(error => {
        console.log(error.response);
        if (error.response.data.errors.some(v => v.param === "recaptcha")) {
          setError("reCAPTCHA check failed, please try again.");
        } else {
          setError("There was an issue sending your response. Please try again later.");
        }
        onError();
      })
    } else {
      setError("Form is not valid, please check fields and try again.");
      onError();
    }
  }

  const validateField = (fields) => {
    const name = fields.name.match(/^[a-zA-Z]+[^0-9]*[ ]*$/i) ? true : false;
    const email = fields.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) ? true : false;
    const message = fields.message.match(/^([a-zA-Z0-9]+[ ]*.*)$/i) ? true : false;
    setValidation({
      name,
      email,
      message,
    });
  }

  const formIsValid = (validation) => {
    if ((validation.name === false) || (validation.email === false) || (validation.message === false)) {
      return false;
    } else {
      return true;
    }
  }
  
  return (
    <React.Fragment>

        <h3>{t('header.contact_form.title')}</h3>
        {error ?
          <p style={{color: "red", fontSize: "0.8rem"}}>{error}</p>
          :
          null
        }

        <form id='contact-us' onSubmit={onSubmit} method="post">
          <div className="contact-us">
            <label htmlFor='name' class="required">{t('header.contact_form.label_name')}</label>
            <input 
              type='text' 
              id='name' 
              name='name' 
              ref={nameRef}
              className='contact-us__field'
              />
              {!validation.name ?
                <p style={{color: "red", fontSize: "0.8rem"}}>Name is invalid.</p>
                :
                null
              }

            <label htmlFor='email' class="required">{t('header.contact_form.label_email')}</label>
            <input 
              type='email' 
              id='email' 
              name='email' 
              ref={emailRef}
              className='contact-us__field'
              />
              {!validation.email ?
                <p style={{color: "red", fontSize: "0.8rem"}}>Email is invalid.</p>
                :
                null
              }

            <label htmlFor='email' class="required">{t('header.contact_form.label_message')}</label> {/*Is htmlFor supposed to be 'message' rather than 'email'?*/}
            <input 
              type='text' 
              id='message' 
              name='message' 
              ref={messageRef}
              className='contact-us__field'
              />
              {!validation.message ?
                <p style={{color: "red", fontSize: "0.8rem"}}>Message is invalid.</p>
                :
                null
              }

            <ReCAPTCHA
              ref={recaptchaRef}
              size="invisible"
              sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY}
            />
          </div>
          <button className="contact-us__button" type='submit'>{t('components.forms.send_button_label')}</button>
        </form>
    </React.Fragment>
  )
}

ContactUsForm.propTypes = {
  /**
   * A callback method to call if submitting the contact form is successful
   */
  onSuccess: PropTypes.func,
  /**
   * A callback method to call if submitting the contact form is not successful
   */
  onError: PropTypes.func,
}

ContactUsForm.defaultProps = {
  onSuccess: () => {},
  onError: () => {},
}

export default ContactUsForm;
