import { useTranslation } from 'react-i18next';

import { ReactComponent as BcLogo } from "../../../assets/BC_logo_horizontal.svg";

import "./header_logo.css";

export const HeaderLogo = () => {
  const { t } = useTranslation('common');

  return (
    <div className="topnav__logo">
      <span className="topnav__logo__title">{t(`header.logo.title_${process.env.REACT_APP_CITY}`)}</span>
      <span className="topnav__logo__powered-by">
        {t('header.logo.tag_line')}
        <a href="https://blueconduit.com/" target="_blank" rel="noreferrer">
          <BcLogo height={32} />
        </a>
      </span>
    </div>
  );
};

export default HeaderLogo;
